.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 2;
  height: 100%;
  background-color: #0f172a;
}

.home-container h1 {
  text-align: center;
  font-size: 3rem;
  font-weight: 400;
  color: #fff;
}

.home-container span {
  background-image: linear-gradient(120deg, #e879f9 0%, #7dd3fc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.btn {
  font-size: 1.25rem;
  font-weight: 500;
  color: #fdf4ff;
  border-color: #fdf4ff;
  border-width: 2px;
  border-radius: 0.375rem;
  padding: 0rem 1rem;
  margin-top: 2rem;
  background-size: 200% 100%;
  background-position: 100% 0;
  transition: background-position 1.5s ease;
}

.btn:hover {
  background-image: linear-gradient(to right, #86198f 0%, #0e7490 70%);
  box-shadow: 0 0 6px #fdf4ff;
  background-position: 0 0;
}

.btn:hover .btn-arrow {
  transform: rotate(90deg);
  transition: 0.8s ease;
}

.home-container a {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  letter-spacing: 0.025em;
}

@media screen and (max-width: 950px) {
  .home-container h1 {
    font-size: 1.25rem;
  }

  .btn {
    font-size: 1rem;
    margin-top: 1rem;
  }
}
