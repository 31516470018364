.about-container {
  display: flex;
  flex-direction: column;
  padding: 6rem 3rem 10rem;
  height: 100%;
  margin: 0 auto;
}

.about-title-wrapper {
  margin: 0 0 6rem 0;
}

.about-title-line {
  display: flex;
  justify-content: center;
}

.about-title {
  display: flex;
  justify-content: center;
  text-align: center;
  color: #444649;
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: -0.025em;
}

.about-line {
  width: 70px;
  height: 4px;
  background-color: #444649;
}

.about-wrapper {
  display: flex;
  margin: 0 auto;
  gap: 10rem;
}

.profile-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}

.about-me {
  font-weight: 600;
  font-size: 2rem;
  color: #4d4a47;
  margin-top: 1.5rem;
  text-align: center;
}

.about-me p {
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  font-weight: 500;
  max-width: 30rem;
  margin: 0 auto;
}

.about-img {
  position: relative;
  width: 300px;
  height: 407.12px; /* width * 0.866 */
  background: #dededd;
  box-sizing: border-box;
  -webkit-clip-path: polygon(
    0% 50%,
    25% 0%,
    75% 0%,
    100% 50%,
    75% 100%,
    25% 100%
  );
  -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

.img-background {
  position: absolute;
  top: 2px; /* equal to border thickness */
  left: 2px; /* equal to border thickness */
  width: 296px; /* container height - (border thickness * 2) */
  height: 403.12px; /* container height - (border thickness * 2) */
  -webkit-clip-path: polygon(
    0% 50%,
    25% 0%,
    75% 0%,
    100% 50%,
    75% 100%,
    25% 100%
  );
  -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

.about-img img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: auto;
  -webkit-clip-path: polygon(
    0% 50%,
    25% 0%,
    75% 0%,
    100% 50%,
    75% 100%,
    25% 100%
  );
  -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

.bar-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  position: relative;
}

.bar-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 35rem;
  height: 2rem;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  margin-bottom: 1rem;
  background-color: #e5e5e5;
  position: relative;
  overflow: hidden;
}

.label {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 7rem;
  font-weight: 600;
  color: #f3f4f6;
  background-color: #0e7490;
  position: relative;
}

.bar {
  height: 2rem;
  outline: 1px solid #164e63 !important;
  background-color: #164e63;
  position: relative;
}

.bar-wrap span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 4rem;
  margin-left: 1rem;
  background-color: #d9d9d9;
  color: #666;
}

.fill {
  animation: fill 2s ease;
}

@keyframes fill {
  0% {
    width: 0;
  }
}

@media screen and (max-width: 950px) {
  .about-wrapper {
    flex-wrap: wrap;
  }

  .bar-wrap {
    width: 30rem;
  }
}

@media screen and (max-width: 600px) {
  .about-container {
    padding: 5rem 1rem;
  }

  .about-title-wrapper {
    margin: 0 0 3rem 0;
  }

  .about-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }

  .bar-wrap {
    height: 1.2rem;
    width: 18rem;
    margin-bottom: 0.6rem;
  }

  .label {
    font-size: 0.6rem;
    height: 1.5rem;
    width: 4rem;
  }

  .fill {
    height: 1.5rem;
  }

  .bar-wrap span {
    display: none;
  }
}
